// @flow
import Window from '../Window';

const isDev = Window.isDev();

export const GDevelopGamePreviews = {
  baseUrl: process.env.REACT_APP_PREVIEW_BASE_URL,
};

console.log(`env`, process.env.REACT_APP_API_BASE_URL);

export const GDevelopGamesPlatform = {
  getInstantBuildUrl: (buildId: string) =>
    isDev
      ? `https://gd.games/instant-builds/${buildId}?dev=true`
      : `https://gd.games/instant-builds/${buildId}`,
  getGameUrl: (gameId: string) =>
    isDev
      ? `https://gd.games/games/${gameId}?dev=true`
      : `https://gd.games/games/${gameId}`,
  getGameUrlWithSlug: (userSlug: string, gameSlug: string) =>
    isDev
      ? `https://gd.games/${userSlug.toLowerCase()}/${gameSlug.toLowerCase()}?dev=true`
      : `https://gd.games/${userSlug.toLowerCase()}/${gameSlug.toLowerCase()}`,
  getUserPublicProfileUrl: (userId: string, username: ?string) =>
    username
      ? `https://gd.games/${username}${isDev ? '?dev=true' : ''}`
      : `https://gd.games/user/${userId}${isDev ? '?dev=true' : ''}`,
};

export const GDevelopFirebaseConfig = {
  apiKey: 'AIzaSyAnX9QMacrIl3yo4zkVFEVhDppGVDDewBc',
  authDomain: 'gdevelop-services.firebaseapp.com',
  databaseURL: 'https://gdevelop-services.firebaseio.com',
  projectId: 'gdevelop-services',
  storageBucket: 'gdevelop-services.appspot.com',
  messagingSenderId: '44882707384',
};

export const GDevelopBuildApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/build'
    : 'https://api.gdevelop.io/build',
};

export const GDevelopUsageApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/usage'
    : 'https://api.gdevelop.io/usage',
};

export const GDevelopReleaseApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/engine'
    : process.env.REACT_APP_API_BASE_URL + '/engine',
};
export const GDevelopAccountApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/account'
    : process.env.REACT_APP_API_BASE_URL + '/account',
};
export const GDevelopStudiotApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/studio'
    : process.env.REACT_APP_API_BASE_URL + '/studio',
};
export const GDevelopUploadApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/upload'
    : process.env.REACT_APP_API_BASE_URL + '/upload',
};

export const GDevelopAssetApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/resources'
    : process.env.REACT_APP_API_BASE_URL + '/resources',
};

export const EngineAssetApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/resources'
    : process.env.REACT_APP_API_BASE_URL + '/resources',
};

export const GDevelopAnalyticsApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/analytics'
    : 'https://api.gdevelop.io/analytics',
};

export const GDevelopGameApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/game'
    : 'https://api.gdevelop.io/game',
};

export const GDevelopUserApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/user'
    : 'https://api.gdevelop.io/user',
};

export const GDevelopPlayApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/play'
    : 'https://api.gdevelop.io/play',
};

export const GDevelopShopApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/shop'
    : 'https://api.gdevelop.io/shop',
};

export const GDevelopProjectApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/games'
    : process.env.REACT_APP_API_BASE_URL + '/games',
};

export const CloudFeaturesApi = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io/cloud'
    : process.env.REACT_APP_API_BASE_URL + '/cloud',
};
export const GDevelopGenerationApi = {
  baseUrl: isDev
    ? 'https://api-dev.gdevelop.io/generation'
    : 'https://api.gdevelop.io/generation',
};

export const GDevelopProjectResourcesStorage = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io'
    : process.env.REACT_APP_API_BASE_URL,
};

export const EngineBaseUrl = {
  baseUrl: isDev
    ? 'https://dev-engine.api.3engine.io'
    : process.env.REACT_APP_API_BASE_URL,
};

export const GDevelopPrivateAssetsStorage = {
  baseUrl: isDev
    ? 'https://private-assets-dev.gdevelop.io'
    : 'https://private-assets.gdevelop.io',
};

export const GDevelopPrivateGameTemplatesStorage = {
  baseUrl: isDev
    ? 'https://private-game-templates-dev.gdevelop.io'
    : 'https://private-game-templates.gdevelop.io',
};

export const GDevelopPublicAssetResourcesStorageBaseUrl =
  'https://asset-resources.gdevelop.io';
export const GDevelopPublicAssetResourcesStorageStagingBaseUrl =
  'https://asset-resources.gdevelop.io/staging';
